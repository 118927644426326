import logo from './logo.svg';
import './App.css';
import DataTable from './components/DataTable';
import Example from './components/Example';
import { Route, Routes } from 'react-router-dom';
import { BrowserRouter } from 'react-router-dom';
import DashboardComponent from './components/DashboardComponent';
import Drogalogin from './components/Drogalogin';
import Navbarr from './components/Navbarr';
import ReportTable from'./components/ReportTable';
import Accountreport from'./components/Accountreport';
import Accounttable from'./components/Accounttable';
import SentReportTable from './components/SentReportTable';
import MediaLinksCard from './components/MediaLinksCard';
import RegisterForm from './components/RegisterForm';
import ChangePasswordComponent from './components/ChangePasswordComponent';
import Botchatdroga from './components/Botchatdroga';
import Dashboard from './components/Dashboard';

function App() {
  return (
<div className="App">
      <BrowserRouter>

        <Routes >
        <Route path='/Example' element={<> <Navbarr /> <Example /></>} />
        <Route path='/DataTable' element={<> <Navbarr /> <DataTable /></>} />
        <Route path='/' element={<><DashboardComponent /></>} />
        <Route path='/login' element={<> <Drogalogin /></>} />
        <Route path='/Navbar' element={<> <Navbarr /></>} />
        <Route path='/ReportTable' element={<> <Navbarr /><ReportTable /></>} />
        <Route path='/Accountreport' element={<> <Navbarr /><Accountreport /></>} />
        <Route path='/Accounttable' element={<> <Navbarr /><Accounttable /></>} />
        <Route path='/SentReportTable' element={<> <SentReportTable /></>} />
        <Route path='/MediaLinksCard' element={<> <Botchatdroga /><Navbarr /> <MediaLinksCard /></>} />
        <Route path='/RegisterForm' element={<> <Navbarr /> <RegisterForm /><Botchatdroga /></>} />
        <Route path='/ChangePassword' element={<> <Navbarr /> <ChangePasswordComponent /></>} />
        <Route path='/Dashboard' element={<> <Navbarr /> <Dashboard /></>} />
        </Routes>

      </BrowserRouter>

    </div>
  );
}

export default App;














