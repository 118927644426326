// src/components/TikTokCard.js
import React from 'react';

const TikTokCard = () => {
  return (
    <div className="bg-white rounded-lg shadow-lg p-6">
      <img src="/tiktok-logo.png" alt="TikTok Logo" className="w-12 h-12 mx-auto mb-4" />
      <h2 className="text-xl font-bold text-center mb-2">TikTok</h2>
      {/* Add content here for TikTok metrics */}
    </div>
  );
};

export default TikTokCard;
