// src/Dashboard.js
import React from 'react';
import FacebookCard from './FacebookCard';
import TwitterCard from './TwitterCard';
import TikTokCard from './TikTokCard';

function Dashboard() {
  return (
    <div className="container mx-auto">
      <h1 className="text-3xl font-bold text-center mb-8">Social Media Dashboard</h1>
      <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
        <FacebookCard />
        <TwitterCard />
        <TikTokCard />
      </div>
    </div>
  );
}

export default Dashboard;
