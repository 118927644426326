import React, { useState, useEffect } from 'react';
import Someofourcustomers from './Someofourcustomers';
import i1 from "../assets/medemer.jpg";
import i2 from "../assets/mede.jpg";
import prosperityImage from '../assets/prospority.jpg';
import ethiopianImage from '../assets/ethiopia.webp';
import { useSpring, animated } from 'react-spring';
import NavAvatar from './NavAvatar';
import securelocalStorage from 'react-secure-storage'
import { Link } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';
// import { CiLink } from 'react-icons/ri'; 
import { HiDocumentReport } from "react-icons/hi";
import { RiAccountPinCircleFill } from "react-icons/ri";
import { TfiLayoutMediaCenterAlt } from "react-icons/tfi";
// import { BiSolidShow } from "react-icons/bi";
import { BiSolidShow } from "react-icons/bi";
import { RiSlideshow4Line } from "react-icons/ri";
import { CiLink } from "react-icons/ci";
// import NavAvatar from './path/to/NavAvatar';
import Botchatdroga from './Botchatdroga'

const DashboardComponent = () => {
  const [activeOption, setActiveOption] = useState('');
  const [rightContentAnimation, setRightContentAnimation] = useState('');
  const [rectangularAnimation, setRectangularAnimation] = useState('');
  const [key, setKey] = useState(0);

  const handleOptionClick = (option) => {
    setActiveOption(option);
  };

  const fadeInStyle = {
    animation: activeOption === 'goToLinkReport' ? 'fadeIn 5.5s ease forwards' : 'none',
    backgroundColor: activeOption === 'goToLinkReport' ? '#3b82f6' : '#2563eb',
  };

  const slideInRightStyle = {
    animation: activeOption === 'goToAccountReport' ? 'slideInRight 5.5s ease forwards' : 'none',
    backgroundColor: activeOption === 'goToAccountReport' ? '#34d399' : '#10b981',
  };

  useEffect(() => {
    const interval = setInterval(() => {
      setRightContentAnimation('animate-bounce');
    }, 50000);

    const rectInterval = setInterval(() => {
      setRectangularAnimation('move-rectangular');
      setTimeout(() => {
        setRectangularAnimation('');
      }, 15000);
    }, 30000);

    return () => {
      clearInterval(interval);
      clearInterval(rectInterval);
    };
  }, []);

  const bounce = useSpring({
    from: { transform: 'translateY(0)' },
    to: { transform: 'translateY(-20px)' },
    config: { duration: 4000 },
    reset: true,
    reverse: true,
    onRest: () => setKey(key + 1),
  });

  const isLoggedIn = !!(
    securelocalStorage.getItem('token') &&
    securelocalStorage.getItem('user')
  );
  const userRole = securelocalStorage.getItem('role');
  const showButton = isLoggedIn && userRole === '1';
  const isMobile = useMediaQuery({ query: '(max-width: 767px)' });

  const navigateInto = () => {
    window.location.href = '/ReportTable';
  };
  const navigateInto1 = () => {
    window.location.href = '/example';
  };
  const navigateIntoaccount = () => {
    window.location.href = '/Accountreport';
  };
  const navigateIntoaccountreport = () => {
    window.location.href = '/Accounttable';
  };
  const mediamonitering = () => {
    window.location.href = '/MediaLinksCard';
  };

  return (
    <div className="flex h-screen overflow-hidden">
      <div className="relative w-1/3 bg-gray-200 p-8" style={{ minWidth: '20.00%' }}>
        <div className="absolute inset-0" style={{
          backgroundImage: `url(${i2})`,
          backgroundSize: 'cover',
          backgroundRepeat: 'no-repeat',
          opacity: 0.5,
        }} />
        <div className="relative">
          {!isMobile && (
            <h1 className="text-2xl font-bold mb-4">የመደመር ትውልድ</h1>
          )}
          <div className="space-y-4">
    
            <div className="space-y-4">
              {isLoggedIn && (
                <div
                  className={`cursor-pointer text-white rounded-md transform transition-all duration-500 hover:scale-90 ${isMobile ? 'block' : ''}`}
                  style={fadeInStyle}
                  onClick={navigateInto}
                >
                  {isMobile ? <CiLink className="text-4xl text-black bg-blue-500 rounded-sm p-1" /> : <div className='p-2 bg-blue-500 text-black rounded-sm hover:bg-yellow-300'>Go to Link Report</div>}
                </div>
              )}
              {isLoggedIn && (
                <div
                  className={`cursor-pointer text-white rounded-md transform transition-all duration-500 hover:scale-90 ${isMobile ? 'block' : ''}`}
                  style={slideInRightStyle}
                  onClick={navigateIntoaccount}
                >
                  {isMobile ? <RiSlideshow4Line className="text-4xl text-black bg-blue-500 rounded-sm p-1" /> : <div className='p-2 bg-blue-500 text-black rounded-sm hover:bg-yellow-300'>Go To Account Report</div>}
                </div>
              )}
              {isLoggedIn && (
                <div
                  className={`cursor-pointer  text-white rounded-md transform transition-all duration-500 hover:scale-90 ${isMobile ? 'block' : ''}`}
                  style={slideInRightStyle}
                  onClick={mediamonitering}
                >
                  {isMobile ? <HiDocumentReport className="text-4xl text-black bg-blue-500 rounded-sm p-1" /> : <div className='p-2 bg-blue-500 text-black rounded-sm hover:bg-yellow-300'>Go To Media Monitering</div>}
                </div>
              )}
              {showButton && (
                <div
                  className={`cursor-pointer text-white rounded-md transform transition-all duration-500 hover:scale-90 ${isMobile ? 'block' : ''}`}
                  style={slideInRightStyle}
                  onClick={navigateIntoaccountreport}
                >
                  {isMobile ? <RiAccountPinCircleFill className="text-4xl text-black bg-blue-500 rounded-sm p-1" /> : <div className='p-2 bg-green-500 text-black rounded-sm hover:bg-yellow-300'>Show Acount Report</div>}
                </div>
              )}
              {showButton && (
                <div
                  className={`cursor-pointer text-white rounded-md transform transition-all duration-500 hover:scale-90 ${isMobile ? 'block' : ''}`}
                  style={fadeInStyle}
                  onClick={navigateInto1}
                >
                  {isMobile ? <TfiLayoutMediaCenterAlt className="text-4xl text-black bg-blue-500 rounded-sm p-1 " /> : <div className='p-2 bg-green-500 text-black rounded-sm hover:bg-yellow-300'>Show Link Report</div>}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      <div
className="relative w-2/3" style={{ width: '80.00%' }}>
<div className="absolute inset-0" style={{
  backgroundImage: `url(${i1})`,
  backgroundSize: 'cover',
  backgroundRepeat: 'no-repeat',
  opacity: 0.5,
}} />
<div className="relative w-2/3 flex justify-center items-center mt-10">
  <div className={``}>
    <h2 className="text-2xl font-bold mb-8">Welcome to Prosperity Report Management System</h2>
    <div className="flex justify-center space-x-8">
      {!isMobile && (
        <>
          <animated.div style={bounce} className
="w-32 h-32 bg-gray-300 rounded-full">
<img src={prosperityImage} alt="PM Abiy Ahmed" className='rounded-full w-full h-full' />
</animated.div>
<animated.div style={bounce} className="w-32 h-32 bg-gray-300 rounded-full">
<img src={ethiopianImage} alt="Prosperity" className='rounded-full w-full h-full'/>
</animated.div>
</>
)}
<Botchatdroga />
</div>
<div className="text-center font-bold">
{!isMobile && (
<>
<p>የመደመር ትውልድ</p>
<p>ዶ/ር አብይ አህመድ</p>
</>
)}
{/* Add more descriptions as needed */}
</div>
</div>
</div>
<div>
<Someofourcustomers/>
</div>
</div>
{/* Add the remaining JSX for the rectangular card */}
<div className={`rectangular-card ${rectangularAnimation}`} />
{isLoggedIn ? (
<div className="absolute top-0 right-0 mt-4 mr-4">
<NavAvatar />
</div>
):(
<Link to="/login" className="absolute top-0 right-0 mt-4 mr-4">
<div className="cursor-pointer bg-blue-500 text-white p-2 rounded-md transform transition-all duration-500 hover:scale-90">
Login
</div>
</Link>
)
}
</div>
);
};

export default DashboardComponent;




